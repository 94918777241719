.rendered-markdown {
  color: var(--text-800);
  color: var(--text-chatcolor);
}

em {
  color: var(--text-400);
}

.rendered-markdown q {
  color: var(--text-800);
  color: var(--text-quote-color);
}

.rendered-markdown img {
  display: inline-block; /* markdown images are expected to be inline-block */
}

.rendered-markdown q::before,
.rendered-markdown q::after {
  content: '';
  border: none; /* necessary for th escreenshot feature or we see weird white borders */
}

.rendered-markdown p:not(:last-child) {
  margin-bottom: 0.4em; /* ensure <p> els are visually separated by empty line */
}

.rendered-markdown pre {
  @apply mt-2 overflow-x-scroll rounded-md bg-[var(--bg-600)] p-1 text-sm text-[var(--text-900)];
}

.rendered-markdown pre::-webkit-scrollbar-thumb {
  @apply bg-[var(--bg-500)];
}

.rendered-markdown pre::-webkit-scrollbar-thumb:hover {
  @apply bg-[var(--bg-300)];
}

.rendered-markdown pre::-webkit-scrollbar-track {
  @apply bg-[var(--bg-800)];
}

.rendered-markdown pre code {
  background-color: unset;
  border-radius: unset;
  padding: unset;
  white-space: pre-wrap;
}

.rendered-markdown ul {
  @apply flex flex-col pl-5;
  list-style-type: disc;
}

.rendered-markdown ol {
  @apply flex flex-col;
  list-style-position: inside;
  counter-reset: item;
}

.rendered-markdown ol li {
  counter-increment: item;
}

.rendered-markdown ol li::before {
  content: counters(item, '.') '. ';
  @apply mr-2;
}

.rendered-markdown > ul > li > ul {
  @apply pl-5;
  list-style-type: circle;
  list-style-position: inside;
}

/* This small manual adjustment is unfortunately necessary for harmony */
.message-date {
  position: relative;
  top: -1px;
}

/* hacky bit to prevent code blocks from causing x overflow */
.msg-with-xs-avatar {
  /* 1rem = the avatars' right padding; added by class pr-4 */
  max-width: calc(100% - 16px - theme('width.6'));
}
.msg-with-sm-avatar,
.msg-with-md-avatar,
.msg-with-lg-avatar {
  max-width: calc(100% - 16px - theme('width.8'));
}
.msg-with-xl-avatar {
  max-width: calc(100% - 16px - theme('width.10'));
}
.msg-with-2xl-avatar {
  max-width: calc(100% - 16px - theme('width.12'));
}
.msg-with-3xl-avatar {
  max-width: calc(100% - 16px - theme('width.16'));
}

@media (min-width: theme('screens.sm')) {
  .msg-with-md-avatar {
    max-width: calc(100% - 16px - theme('width.10'));
  }
  .msg-with-lg-avatar {
    max-width: calc(100% - 16px - theme('width.12'));
  }
  .msg-with-xl-avatar {
    max-width: calc(100% - 16px - theme('width.16'));
  }
  .msg-with-2xl-avatar {
    max-width: calc(100% - 16px - theme('width.20'));
  }
  .msg-with-3xl-avatar {
    max-width: calc(100% - 16px - theme('width.24'));
  }
}


.loop {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}