.app {
  height: 100vh;
  height: 100dvh;
  /* height: -webkit-fill-available; */
  overflow-y: overlay;
}

.app .content-background {
  background-color: rgba(var(--rgb-bg-900), 0.9);
  @apply h-max rounded-lg py-4;
}

/* hack while we figure out why text boxes aren't the correct size */
.text-input-min-h-override {
  min-height: 120px !important;
}

.drawer {
  height: calc(100vh);
  height: calc(100dvh);
  position: absolute;
  top: 0;
  /* top: 48px; */
  left: 0;
  transition: opacity 250ms ease;
  transition: left 400ms ease;
  @apply bg-[var(--bg-800)] sm:relative sm:top-0 sm:clear-none;
  @apply z-20 sm:z-0;
}

@media (min-width: 1024px) {
  .drawer {
    height: 100vh;
    height: 100dvh;
  }
}

.drawer--hide {
  animation-fill-mode: forwards;
  animation: hideDrawer 0s ease 800ms;
  @apply left-[-100vw] sm:left-0 sm:opacity-100;
}

.drawer__content {
  /* position: fixed; */
  top: 0;
  left: 0;
  min-width: 302px;
  max-width: 302px;
  height: calc(100% - 16px);
  transition: 250ms ease;
}

.drawer > * > a.active {
  @apply bg-[var(--hl-900)];
}

@keyframes hideDrawer {
  to {
    visibility: hidden;
  }
}

.slot-sticky {
  position: sticky;
  top: 0;
}

.helper-text {
  @apply mt-[-0.125rem] pb-1 text-sm text-[var(--text-600)];
}
